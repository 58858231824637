import angular from 'angular';
import GmfEditFeatureComponent from 'gmf/editing/editFeatureComponent.js';
import pnList from '../pronaturalist/pronaturaList.js';

/**
 * Override gmf/editing/editFeatureComponent partial with a custom one.
 */
GmfEditFeatureComponent.run(
  /* @ngInject */ ($templateCache) => {
    // @ts-ignore: webpack
    $templateCache.put('gmf/editing/editFeatureComponent', require('./editFeatureComponent.html'));
  }
);

// Set a module with for the pnEditComponent.
const module = angular.module('pnEditFeature', [pnList.name]);

export default module;
