import angular from 'angular';

import 'jquery-ui/ui/widgets/resizable.js';
import 'ngeo/sass/jquery-ui.scss';
import pnFormService from '../formservices/formService.js';
import './component.scss';

/**
 * @typedef {Object} pnParzellenFormsPopupSource
 * @property {string} id The id of the parzellen.
 * @property {string} type The type of forms to query.
 */

/**
 * @type {angular.IModule}
 * @hidden
 */
const myModule = angular.module('pnParzellenFormsPopup', [pnFormService.name]);

myModule.value(
  'pnParzellenFormsPopupTemplateUrl',
  /**
   * @param {JQuery} $element Element.
   * @param {angular.IAttributes} $attrs Attributes.
   * @return {string} Template.
   */
  ($element, $attrs) => {
    const templateUrl = $attrs.pnParzellenFormsPopupTemplateUrl;
    return templateUrl !== undefined ? templateUrl : 'pn/parzellenFormsPopup';
  }
);

myModule.run(
  /**
   * @ngInject
   * @param {angular.ITemplateCacheService} $templateCache
   */
  ($templateCache) => {
    // @ts-ignore: webpack
    $templateCache.put('pn/parzellenFormsPopup', require('./component.html'));
  }
);

/**
 * @param {JQuery} $element Element.
 * @param {angular.IAttributes} $attrs Attributes.
 * @param {function(JQuery, angular.IAttributes): string} gmfDisplayquerywindowTemplateUrl Template
 *    function.
 * @return {string} Template URL.
 * @ngInject
 * @private
 * @hidden
 */
function pnParzellenFormsPopupTemplateUrl($element, $attrs, pnParzellenFormsPopupTemplateUrl) {
  return pnParzellenFormsPopupTemplateUrl($element, $attrs);
}

/**
 * @ngdoc component
 * @ngname pnParzellenFormsPopup
 */
const pnParzellenFormsPopupComponent = {
  controller: 'pnParzellenFormsPopupController',
  templateUrl: pnParzellenFormsPopupTemplateUrl,
};

myModule.component('pnParzellenFormsPopup', pnParzellenFormsPopupComponent);

/**
 * @hidden
 */
class pnParzellenFormsPopupController {
  /**
   * @param {JQuery} $element Element.
   * @param {angular.IScope} $scope Angular scope.
   * @params {pnFormService} pnFormService service to get forms for a parzellen.
   * @param {pnParzellenFormsPopupSource} pnParzellenFormsPopupSource the source of the forms to query.
   * @ngInject
   * @ngdoc controller
   * @ngname pnParzellenFormsPopupController
   */
  constructor($element, $scope, pnFormService, pnParzellenFormsPopupSource) {
    /**
     * @type {JQuery}
     */
    this.$element_ = $element;
    /**
     * @type {Element|string}
     */
    this.draggableContainment = 'body';

    /**
     * @type {pnFormService}
     */
    this.pnFormService_ = pnFormService;

    /**
     * @type {boolean}
     */
    this.clearing_ = false;

    /**
     * @type {boolean}
     */
    this.open = false;

    /**
     * @type {string}
     */
    this.title = null;

    /**
     * @type {Object<string, string>[]}
     */
    this.content = null;

    /**
     * @type {string[]}
     */
    this.columns = [];

    /**
     * @type {pnParzellenFormsPopupSource}
     */
    this.pnParzellenFormsPopupSource_ = pnParzellenFormsPopupSource;

    $scope.$watchCollection(
      () => this.pnParzellenFormsPopupSource_,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.pnParzellenFormsPopupSource_ = newValue;
          // Don't re-opens the popup on clear !
          if (this.clearing_) {
            this.clearing_ = false;
            return;
          }
          this.updateState_();
        }
      }
    );
  }

  /**
   * Initialize the controller.
   */
  $onInit() {
    const windowContainer = this.$element_.find('.pn-displaywindow .windowcontainer');
    windowContainer.draggable({
      handle: '.header',
      containment: this.draggableContainment,
    });
    windowContainer.resizable({
      handles: 'all',
      minHeight: 200,
      minWidth: 400,
    });
  }

  close() {
    this.clear_();
    this.open = false;
  }

  clear_() {
    this.clearing_ = true;
    this.content = null;
    this.title = null;
    this.columns = [];
    this.pnParzellenFormsPopupSource_.id = null;
    this.pnParzellenFormsPopupSource_.type = null;
  }

  show() {
    this.open = true;
  }

  /**
   * @param {Object<string, string>} item
   * @return {string[]} values
   */
  getValueForContentItem(item) {
    return this.columns.map((col) => item[col]);
  }

  updateState_() {
    const id = this.pnParzellenFormsPopupSource_.id;
    const type = this.pnParzellenFormsPopupSource_.type;
    if (id !== null && type !== null) {
      this.pnFormService_.getAllItemsForOneParzellen(id, type).then(
        (success) => {
          this.setResults_(success.data, type, id);
        },
        (reject) => {
          this.setNoResults_();
        }
      );
    } else {
      this.setNoResults_();
    }
  }

  setNoResults_() {
    this.title = 'Keine Form';
    this.content = null;
    this.show();
  }

  /**
   * @param {Object<string, string>[]} response
   * @param {string} type
   * @param {string} id
   */
  setResults_(content, type, id) {
    this.title = `${type} for location ${id}`;
    this.setColumns_(content);
    this.content = content;
    this.show();
  }

  /**
   * @param {Object<string, string>[]} response
   */
  setColumns_(content) {
    const columns = [];
    content.forEach((item) => {
      const keys = Object.keys(item);
      columns.push(...keys.filter((key) => !columns.includes(key)));
    });
    this.columns = columns;
  }
}

myModule.controller('pnParzellenFormsPopupController', pnParzellenFormsPopupController);

export default myModule;
