import angular from 'angular';
import {getUid as olUtilGetUid} from 'ol/util.js';
import {listen} from 'ol/events.js';
import {Controller} from 'ngeo/editing/attributesComponent.js';
import NgeoAttributesComponent from 'ngeo/editing/attributesComponent.js';

Controller.prototype.isMapFeature = true;

/**
 * OVERRIDE for pronatura: Support non mapFeature features.
 * Initialize the component.
 */
Controller.prototype.$onInit = function () {
  if (!this.feature) {
    throw new Error('Missing feature');
  }

  // OVERRIDE This "if" has been added for Pronatura purposes check
  // if we have a feature or another item
  if (typeof this.feature.getProperties !== 'function') {
    this.properties = this.feature;
    this.isMapFeature = false;
  } else {
    this.properties = this.feature.getProperties();
  }

  // Listen to the feature inner properties change and apply them to the form
  const uid = olUtilGetUid(this);

  // Set properties before starting to listen to changes to avoid multi AngularJS apply error.
  this.attributes.forEach((attribute) => {
    this.sanitize_(attribute);
  });

  if (this.isMapFeature) {
    this.ngeoEventHelper_.addListenerKey(
      uid,
      listen(this.feature, 'propertychange', this.handleFeaturePropertyChange_, this)
    );
  }
};

/**
 * OVERRIDE for pronatura: Support non mapFeature features.
 * Never keep a undefined values, use null.
 * On boolean, replace null by false.
 * On date, datetime and time replace empty string by null.
 * @param {import('ngeo/format/Attribute.js').Attribute} attribute
 * @private
 */
Controller.prototype.sanitize_ = function (attribute) {
  let value = this.properties[attribute.name];
  if (value === undefined) {
    value = null;
  }
  if (attribute.type === 'boolean' && value === null) {
    value = false;
  } else if (attribute.format && !value) {
    // Case of date, datetime or time.
    // Shouldn't be set to an empty string
    value = null;
  }
  this.properties[attribute.name] = value;
  // OVERRIDE This "if" has been added for Pronatura purposes.
  if (this.isMapFeature) {
    this.feature.set(attribute.name, value);
  }
};

/**
 * Override ngeo/editing/attributeComponent partial with a custom one.
 */
NgeoAttributesComponent.run(
  /* @ngInject */ ($templateCache) => {
    // @ts-ignore: webpack
    $templateCache.put('ngeo/editing/attributescomponent', require('./attributesComponent.html'));
  }
);
